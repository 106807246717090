<template>
  <div class='upload-address-proof-main-wrapper'>
    <a-form>
      <!-- <a-spin :spinning='loading' :indicator='indicator' /> -->
      <a-row style='display: unset'>
        <a-col>
          <div style='margin-top: 100px'>
            <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
            <div>
              <div class='upload-address-proof-title'>Let's verify your address</div>
              <div class='upload-address-proof-stepper'>
                Hello {{ clientName }}!
              </div>

              <div class='upload-address-proof-sub-title-description'>
                We will read data automatically from your uploaded aadhaar.
              </div>
              <div class='upload-address-proof-sub-title-description'>
                You can also edit details after uploading aadhaar.
              </div>

              <div class='upload-aadhar-card-upload-btn'>
                <a-upload
                  v-model:file-list='frontFileList'
                  :max-Count='1'
                  accept='image/png, image/jpeg, image/jpg'
                  @change='onChangeFrontImage'
                  :customRequest='onUploadFrontAadhar'
                  :before-upload='beforeUpload'
                >
                  <a-button :disabled='disable'
                    style='width: 100%; height: 44px; border: 1px solid #79a89b'
                  >
                  <span style="float: left;">
                    Upload front copy of Aadhaar
                  </span>
                    <cloud-upload-outlined style="font-size: 20px;float: right;" />
                  </a-button>
                </a-upload>
                <div
                  v-if='ifFileUpload'
                  style='color: red; text-align: initial'
                >
                  Please provide your front copy of Aadhar
                </div>
              </div>
              <div class='upload-aadhar-card-upload-btn'>
                <a-upload
                  v-model:file-list='backFileList'
                  :max-Count='1'
                  accept='image/png, image/jpeg, image/jpg'
                  :before-upload='beforeUpload'
                  @change='onChangeBackImage'
                  :customRequest='onUploadBackAadhar'
                >
                  <a-button :disabled='disable'
                    style='width: 100%; height: 44px; border: 1px solid #79a89b'
                  >
                    <span style="float: left;">
                    Upload back copy of Aadhaar
                  </span>
                    <cloud-upload-outlined style="font-size: 20px;float: right;" />
                  </a-button>
                </a-upload>
                <div
                  v-if='ifBackFileUpload'
                  style='color: red; text-align: initial'
                >
                  Please provide your back copy of Aadhar
                </div>
              </div>
              <div class='pan-details-submit-btn-wrapper'>
                <a-button type='primary' @click='onClickContinue' :loading="load"
                  >Submit</a-button
                >
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import {
  onMounted,
  ref,
  reactive,
  // h,
} from 'vue';
import { Form } from 'ant-design-vue';
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import STAGES from '../../../constants/stages';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';

const { useForm } = Form;

export default {
  components: {
    CloudUploadOutlined,
  },
  setup() {
    const store = useStore();
    const load = ref(false);
    const disable = ref(false);
    const clientName = ref('');
    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          clientName.value = response.data.data.prefill.name;
          store.dispatch('onboardingStore/updateClientName', response.data.data.prefill.name);
        })
        .catch(() => {
        });
    });
    const router = useRouter();
    const modelRef = reactive({
      type: 'AADHAR',
    });
    const rulesRef = reactive({
      type: [
        {
          required: true,
          message: 'Please Select',
        },
      ],
    });

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef,
    );

    const frontFileList = ref([]);
    const backFileList = ref([]);
    const ifFileUpload = ref(false);
    const ifBackFileUpload = ref(false);
    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        const message = 'Validation error';
        const description = 'Only png, jpg, and jpeg images are accepted';
        Operations.notificationError(message, description);
      }
      return isJpgOrPng;
    };
    const onChangeFrontImage = () => {
      if (frontFileList.value.length > 0) {
        ifFileUpload.value = false;
      }
    };
    const onChangeBackImage = () => {
      if (backFileList.value.length > 0) {
        ifBackFileUpload.value = false;
      }
    };
    const s3FrontLink = ref('');
    const s3BackLink = ref('');
    // const loading = ref(false);

    // const indicator = h(LoadingOutlined, {
    //   style: {
    //     fontSize: '25px',
    //   },
    //   spin: ref(loading),
    // });

    const waitMessage = null;
    const waitFrontDescription = 'Please wait while your document is being uploaded';
    const waitBackDescription = 'Please wait while your document is being uploaded';
    const ocrLoadDescription = 'Please wait while we verify your details';

    const onUploadFrontAadhar = ({ file }) => {
      const data = new FormData();
      data.append('file', file);
      data.append('documentType', 'AADHAAR_FRONT');
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      Operations.showNotification(waitMessage, waitFrontDescription);
      // loading.value = true;
      disable.value = true;
      services
        .uploadDocument(data, config)
        .then((uploadResponse) => {
          Operations.showNotification(waitMessage, waitFrontDescription);
          store.dispatch('onboardingStore/updateFrontAadhar', uploadResponse.data.name);
          // loading.value = false;
          disable.value = false;
          s3FrontLink.value = uploadResponse.data.url;
        })
        .catch(() => {
          Operations.showNotification(waitMessage, waitFrontDescription);
          frontFileList.value = [];
          // loading.value = false;
          disable.value = false;
        });
    };
    const onUploadBackAadhar = ({ file }) => {
      const data = new FormData();
      data.append('file', file);
      data.append('documentType', 'AADHAAR_BACK');
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      Operations.showNotification(waitMessage, waitBackDescription);
      // loading.value = true;
      disable.value = true;
      services
        .uploadDocument(data, config)
        .then((uploadResponse) => {
          Operations.showNotification(waitMessage, waitBackDescription);
          store.dispatch('onboardingStore/updateBackAadhar', uploadResponse.data.name);
          // loading.value = false;
          disable.value = false;
          s3BackLink.value = uploadResponse.data.url;
        })
        .catch(() => {
          Operations.showNotification(waitMessage, waitBackDescription);
          backFileList.value = [];
          // loading.value = false;
          disable.value = false;
        });
    };

    const onClickContinue = () => {
      if (frontFileList.value.length === 0) {
        ifFileUpload.value = true;
      } else if (backFileList.value.length === 0) {
        ifBackFileUpload.value = true;
      } else {
        validate().then(() => {
          const data = [
            {
              documentType: 'AADHAAR_FRONT',
              image: s3FrontLink.value,
            },
            {
              documentType: 'AADHAAR_BACK',
              image: s3BackLink.value,
            },
          ];
          // loading.value = true;
          load.value = true;
          if (load.value === true) {
            Operations.showNotification(waitMessage, ocrLoadDescription);
          }
          disable.value = true;
          services
            .uploadAddressProof(data)
            .then((response) => {
              store.dispatch('onboardingStore/updateUploadedAadhar', response.data);
              // loading.value = false;
              load.value = false;
              disable.value = false;
              Operations.showNotification(waitMessage, ocrLoadDescription);
              router.push('/onboarding/address/verification');
              store.dispatch('onboardingStore/updateCurrentStage', STAGES.STAGE_TYPE.ADDRESS_PROOF);
            })
            .catch((error) => {
              load.value = false;
              disable.value = false;
              Operations.showNotification(waitMessage, ocrLoadDescription);
              const systemError = Operations.axiosErrorHandler(error);
              const message = systemError.status;
              const description = systemError.message
                ? systemError.message
                : '';
              Operations.notificationError(message, description);
            });
        });
      }
    };
    return {
      onClickContinue,
      value1: ref(),
      checked: ref(false),
      clientName,
      validateInfos,
      resetFields,
      modelRef,
      frontFileList,
      ifFileUpload,
      onUploadFrontAadhar,
      beforeUpload,
      onChangeFrontImage,
      onChangeBackImage,
      backFileList,
      onUploadBackAadhar,
      ifBackFileUpload,
      s3FrontLink,
      s3BackLink,
      // indicator,
      // loading,
      load,
      disable,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/uploadAddressProof.scss';
</style>
